import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-multi-carousel";
import convertNumber from "../../utils/convertNumber";
import { Link } from "gatsby";
import ViewNowBtn from "../viewNowBtn";

import styles from "../../styles/components/landing/carousel.module.scss";

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CarouselComponent = ({ items }) => {
  return (
    <div className={styles.carousel}>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <Carousel
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
        >
          {items.map((item) => (
            <div className={styles.carouselItem} key={item.id}>
              <Link to={`/shop/${item.slug}`}>
                <img src={item.image} />
                <div className="mt-3 text-center">
                  <p className="small mb-2">{item.supplier}</p>
                  <h1 className={`${styles.itemName} m-0 mb-3`}>{item.name}</h1>
                  {!!item.price && (
                    <p className={styles.itemPrice}>
                      Rs. {convertNumber(item.price)}
                    </p>
                  )}
                </div>
              </Link>

              <ViewNowBtn link={`/shop/${item.slug}`} primary />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselComponent;
