import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { connect } from "react-redux";
import Carousel from "./carousel";
import Container from "react-bootstrap/Container";
import { getProducts } from "../../state/actions/products";
import styles from "../../styles/components/landing/supplementsPg.module.scss";

const SupplementsPg = ({
  title = "Featured Products",
  getProducts,
  products,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSupplement {
        edges {
          node {
            contentful_id
            slug
            name
            flavor
            supplier
            category
            productImages {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const [productList, setProductList] = useState(
    data.allContentfulSupplement.edges.map((edge) => {
      return {
        id: edge.node.contentful_id,
        slug: edge.node.slug,
        name: edge.node.name,
        supplier: edge.node.supplier,
        category: edge.node.category,
        image: edge.node.productImages[0].file.url,
      };
    })
  );

  const hydrateProducts = () => {
    if (products.length !== 0) {
      const newProductArr = [];

      productList.map((product) => {
        const details = products.find((item) => item.productID === product.id);
        newProductArr.push({ ...product, price: details.price });
      });

      setProductList(newProductArr);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    hydrateProducts();
  }, [products]);

  const [selected, setSelected] = useState("All");
  const filteredProducts = productList
    .filter((product) => selected === "All" || product.category === selected)
    .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

  return (
    <div className={styles.body}>
      <Container>
        <h1 className={styles.pageTitle}>{title}</h1>
        <div className={styles.selectionPanel}>
          <div
            className={
              selected === "All"
                ? `${styles.selection} ${styles.active}`
                : `${styles.selection}`
            }
            onClick={() => setSelected("All")}
          >
            <span>All</span>
          </div>

          <div
            className={
              selected === "Protein"
                ? `${styles.selection} ${styles.active}`
                : `${styles.selection}`
            }
            onClick={() => setSelected("Protein")}
          >
            <span>Protein</span>
          </div>
        </div>
        <div>
          <Carousel items={filteredProducts} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplementsPg);
